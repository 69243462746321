const initialState = {
  implicitCategory: null, // For being able to go back from store to category
  storeHovered: null,
}

export default (state: Object = initialState, action: any = {}) => {
  switch (action.type) {
    case "SET_HOVERED":
      return {
        ...state,
        storeHovered: action.hovered,
      }
    case "SET_IMPLICIT_CATEGORY":
      return {
        ...state,
        implicitCategory: action.category,
      }
    default:
      return state
  }
}

import * as React from "react"
import ReactGa4 from "react-ga4"
import isBrowser from "./utils/isBrowser"
import { WindowLocation } from "@reach/router"

if (process.env.GATSBY_GA4_ID) {
  ReactGa4.initialize(process.env.GATSBY_GA4_ID)
} else {
  console.error("GA4 analytics ID missing!")
}

interface TrackerProps {
  location: WindowLocation
}

class Tracker extends React.Component<TrackerProps> {
  componentDidMount = () => {
    this.addGAErrorReporter()
    const page = this.props.location.pathname
    this.trackPage(page)
  }

  componentDidUpdate = (prevProps: TrackerProps) => {
    const prevPage = prevProps.location.pathname
    const currentPage = this.props.location.pathname

    if (currentPage !== prevPage) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" })
      this.trackPage(currentPage)
    }
  }

  addGAErrorReporter = () => {
    if (!isBrowser) {
      return
    }
    window.addEventListener("error", (errorEvent) => {
      console.log(errorEvent)
      const errorUrl = window.location.href
      // @ts-ignore
      const errorFile = `${errorEvent.filename}: line ${errorEvent.lino}, col ${errorEvent.colno}`
      const errorString = `[${errorEvent.message}]
      url: ${errorUrl}
      file: ${errorFile}`
      ReactGa4.event("exception", { description: errorString })
    })
  }

  trackPage = (page: string) => {
    if (isBrowser) {
      // Ga4 automatically tracks pageviews
    }
  }

  render = () => null
}

export default Tracker

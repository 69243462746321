export const assets = [
  "pin_vintage",
  "pin_vintage_gray",
  "pin_thrift",
  "pin_thrift_gray",
  "pin_sell",
  "pin_sell_gray",
  "pin_donate",
  "pin_donate_gray",
  "pin_designer",
  "pin_designer_gray",
  "pin_default",
  "pin_default_gray",
  "pin_consign",
  "pin_consign_gray",
]

export function assetNameOnly(fileName: string): string {
  return fileName.replace(/\.\w+$/, "")
}

const tagToAsset = {
  vintage: "vintage",
  thrift: "thrift",
  designer: "designer",
  consign: "consign",
  consignment: "consign",
  sell: "sell",
  donate: "donate",
}

export function tagsToAssetName(tags?: string[]): string {
  if (!tags) return "default"
  const tagMatch = tags.find((tag) => tagToAsset[tag] != undefined)
  return tagMatch ?? "default"
}

export function pinAssetNameForTags(tags: Array<string>): string {
  const assetName = tagsToAssetName(tags)
  return `pin_${assetName}`
}

export function iconAssetUrlForTags(tags: Array<string>): string {
  const assetName = tagsToAssetName(tags)
  return `/img/icon_${assetName}@2x.png`
}

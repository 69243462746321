import React, { useEffect } from "react"
import { createGlobalStyle } from "styled-components"
import {
  cssComputerOnly,
  primaryColor,
  screenWidthBreakPoint,
} from "./styleConstants"

const Styles = createGlobalStyle`
  html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);

    ${cssComputerOnly} {
      /* Make the page non-bouncy on large screens */
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
    font-size: 16px;

    ${cssComputerOnly} {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: auto;
    }

  }

  a {
    text-decoration: none;
    color: ${primaryColor};
    font-weight: 500;
  }

  :root {
    --content-width: 375;
  }
`

const onResize = () => {
  const root = document.documentElement
  root.style.setProperty(
    "--content-width",
    String(window.innerWidth < screenWidthBreakPoint ? window.innerWidth : 375)
  )
}

export default function GlobalStyles() {
  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return <Styles />
}

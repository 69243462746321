// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-views-about-about-container-tsx": () => import("./../../../src/views/about/AboutContainer.tsx" /* webpackChunkName: "component---src-views-about-about-container-tsx" */),
  "component---src-views-about-about-content-tsx": () => import("./../../../src/views/about/AboutContent.tsx" /* webpackChunkName: "component---src-views-about-about-content-tsx" */),
  "component---src-views-home-home-container-tsx": () => import("./../../../src/views/home/HomeContainer.tsx" /* webpackChunkName: "component---src-views-home-home-container-tsx" */),
  "component---src-views-logo-and-images-logo-and-images-container-tsx": () => import("./../../../src/views/logoAndImages/LogoAndImagesContainer.tsx" /* webpackChunkName: "component---src-views-logo-and-images-logo-and-images-container-tsx" */),
  "component---src-views-picks-picks-container-tsx": () => import("./../../../src/views/picks/PicksContainer.tsx" /* webpackChunkName: "component---src-views-picks-picks-container-tsx" */),
  "component---src-views-privacy-privacy-container-tsx": () => import("./../../../src/views/privacy/PrivacyContainer.tsx" /* webpackChunkName: "component---src-views-privacy-privacy-container-tsx" */),
  "component---src-views-privacy-privacy-content-tsx": () => import("./../../../src/views/privacy/PrivacyContent.tsx" /* webpackChunkName: "component---src-views-privacy-privacy-content-tsx" */),
  "component---src-views-store-store-container-tsx": () => import("./../../../src/views/store/StoreContainer.tsx" /* webpackChunkName: "component---src-views-store-store-container-tsx" */),
  "component---src-views-stores-stores-container-tsx": () => import("./../../../src/views/stores/StoresContainer.tsx" /* webpackChunkName: "component---src-views-stores-stores-container-tsx" */),
  "component---src-views-terms-terms-container-tsx": () => import("./../../../src/views/terms/TermsContainer.tsx" /* webpackChunkName: "component---src-views-terms-terms-container-tsx" */),
  "component---src-views-terms-terms-content-tsx": () => import("./../../../src/views/terms/TermsContent.tsx" /* webpackChunkName: "component---src-views-terms-terms-content-tsx" */)
}


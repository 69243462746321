import React, { ReactElement, useCallback, useState, useContext } from "react"

import Confirmation from "src/components/Confirmation"

interface Props {
  children: ReactElement | ReactElement[]
}

const ModalPortalContext = React.createContext<null | ShowConfirmationFn>(null)
const ModalPortalProvider = ModalPortalContext.Provider

type ShowConfirmationFn = (text: string) => void

export default function ModalPortal({ children }: Props): ReactElement {
  const [confirmation, setConfirmation] = useState("")
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  // Set value of ModalPortal context to my openModal method

  const showConfirmation = useCallback((text: string) => {
    setConfirmation(text)
    setConfirmationOpen(true)
  }, [])

  const hideConfirmation = () => {
    setConfirmationOpen(false)
  }

  return (
    <ModalPortalProvider value={showConfirmation}>
      {children}

      <div style={{ zIndex: 10000, position: "relative" }}>
        <Confirmation
          text={confirmation}
          visible={confirmationOpen}
          onClose={hideConfirmation}
        />
      </div>
    </ModalPortalProvider>
  )
}

export function useConfirmation() {
  return useContext(ModalPortalContext)
}

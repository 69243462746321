import { Category } from "src/types"
import slugify from "../utils/slugify"

export function categoryPath(name: string): string {
  return `/stores/${slugify(name)}`
}

export function categoryPathForCategory(category: Category): string {
  if (!category?.name) return "/" // This shouldn't happen, but just in case
  if (category.type === "picks") {
    return `/picks/${slugify(category.name)}`
  } else {
    return `/stores/${slugify(category.name)}`
  }
}

export function categoryStorePath(category: string, name: string): string {
  return `/stores/${slugify(category)}/${slugify(name)}`
}

export function picksStorePath(picksPerson: string, name: string): string {
  return `/picks/${slugify(picksPerson)}/${slugify(name)}`
}

export function storePath(name: string): string {
  return `/store/${slugify(name)}`
}

import { serializeError } from "serialize-error"
import isBrowser from "src/utils/isBrowser"

// If browser, set global error handler
if (isBrowser) {
  window.addEventListener("error", reportCrash)
}

let reportsSent = 0
const MAX_REPORTS_PER_SESSION = 3

function reportCrash(errorEvent: any) {
  if (reportsSent > MAX_REPORTS_PER_SESSION) {
    return
  }

  // Opaque / third party error https://raygun.com/blog/script-error/
  const isOpaqueError =
    errorEvent?.error?.message === "Script error." ||
    errorEvent?.message === "Script error."

  // Submit only non-opaque ones
  if (isOpaqueError === false) {
    const report = {
      clientDate: new Date().toISOString(),
      errorType: "web-js",
      environment: getEnvironmentInfo(),
      page: window.location.href,
      error: errorEvent.error
        ? serializeError(errorEvent.error)
        : serializeError(errorEvent),
    }
    sendReport(report)
    reportsSent++
  }
}

function getEnvironmentInfo(): { [key: string]: any } {
  try {
    return {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      vendor: navigator.vendor,
      url: window.location.href,
    }
  } catch (error) {
    return {
      envInfoError: true,
    }
  }
}

async function sendReport(report): Promise<void> {
  await fetch("/.netlify/functions/report", {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: JSON.stringify(report, null, 2),
  })
  console.log("Crash/error report sent")
}

// Uncomment for testing JS errors -------------------------------------------
// setTimeout(() => intentionallyMissingFunction(), 10000);

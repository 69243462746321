import React, { ReactElement, useEffect } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

interface Props {
  text: string
  visible: boolean
  onClose: () => void
}

export default function Confirmation({
  text,
  visible,
  onClose,
}: Props): ReactElement {
  useEffect(() => {
    let id
    if (visible === true) {
      id = setTimeout(() => onClose(), 1000)
    }
    return () => clearTimeout(id)
  }, [visible])

  return (
    <FullScreenDiv>
      <AnimatePresence>
        {visible && (
          <>
            <DimmerDiv
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.1 }}
              exit={{ opacity: 0 }}
            />
            <ToastDiv
              transition={{ duration: 0.1, type: "circInOut" }}
              initial={{ opacity: 0, y: 20, scale: 0.8 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 0, scale: 0.8 }}
            >
              {text}
            </ToastDiv>
          </>
        )}
      </AnimatePresence>
    </FullScreenDiv>
  )
}

const FullScreenDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
const DimmerDiv = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.1;
`

const ToastDiv = styled(motion.div)`
  padding: 20px 40px;
  background: white;
  border-radius: 22px;
  box-shadow: 0 2px 44px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: 500;
  z-index: 100;
`

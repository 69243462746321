import { Category } from "src/types"

export const setHovered = (id: string | null) => {
  return {
    type: "SET_HOVERED",
    hovered: id,
  }
}

export const setImplicitCategory = (category?: Category) => {
  return {
    type: "SET_IMPLICIT_CATEGORY",
    category,
  }
}

const initialState = {
  activeTab: "Area",
  searchQuery: "",
  printDialogVisibiity: false,
  shareDialogVisibility: false,
}

export default (state: Object = initialState, action: any = {}) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.activeTab,
      }
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.query,
      }
    default:
      return state
  }
}

import { connect } from "react-redux"

import Map from "./Map"

import { setHovered } from "src/actions/storesActions"

const mapStateToProps = (state) => ({ ...state.stores, ...state.screen })

const mapDispatchToProps = (dispatch) => {
  return {
    setHovered: (id?: string) => {
      dispatch(setHovered(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)

import * as React from "react"
import styled from "styled-components"
import get from "lodash/get"
import { navigate } from "gatsby"

import slugify from "src/utils/slugify"
import * as assetHelper from "src/utils/assetHelper"
import isTouchDevice from "src/utils/isTouchDevice"
import isBrowser from "src/utils/isBrowser"
import {
  screenWidthBreakPoint,
  cssComputerOnly,
} from "src/styles/styleConstants"

// Conditional dependencies, ran only in browser
// Instead of dynamic import(), using require for synchronous importing
let browserOnly: { [key: string]: any } = {}
if (isBrowser) {
  // Mapbox library works only in browser
  browserOnly.mapboxgl = require("mapbox-gl")
  browserOnly.LngLatBounds = require("mapbox-gl").LngLatBounds
  browserOnly.mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN

  browserOnly.tw = document.createElement("div")
  browserOnly.tw.className = "waveContainer"
  const twAnim = document.createElement("div")
  twAnim.className = "waveAnim"
  browserOnly.tw.appendChild(twAnim)
}

import "mapbox-gl/dist/mapbox-gl.css"
import "src/styles/mapboxOverrides.css"
import "src/styles/wave.css"
import { PointLike, LngLatBoundsLike, GeoJSONSource } from "mapbox-gl"
import { Category } from "src/types"
import { categoryPathForCategory } from "src/utils/paths"

const MapDiv = styled.div`
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background: #eeeeee;
  user-select: none;

  ${cssComputerOnly} {
    height: 100%;
  }
`

const defaultBounds: LngLatBoundsLike = {
  nyc: [
    [-74.02990169341369, 40.664],
    [-73.88509717621733, 40.81],
  ],
  la: [
    [-118.48, 33.78],
    [-118.15, 34.145],
  ],
}[process.env.GATSBY_CITY]

interface MapProps {
  stores: {
    names: string[]
    byName: {
      [id: string]: {
        name: string
        center: [number, number]
        tags: string[]
      }
    }
  }
  category: Category
  storeFocused: string
  storeHovered: string
  setHovered: Function
  height?: number
  history: Object
}

interface MapState {
  ready: boolean
  bounds?: LngLatBoundsLike
  autoBounds: boolean
  browsingAreaMode: boolean
  wideView: boolean
  wheelTimeout?: number
  resizeTimeout?: number
}

class Map extends React.Component<MapProps> {
  // this.map is the Mapbox object
  // It will have "markers" source for all markers
  map: mapboxgl.Map
  mapElement = null
  mapState: MapState = {
    ready: false,
    bounds: defaultBounds,
    autoBounds: true,
    browsingAreaMode: false,
    wideView: window.innerWidth >= screenWidthBreakPoint,
    wheelTimeout: null,
    resizeTimeout: null,
  }

  componentDidMount = () => {
    this.mapElement = document.getElementById("map")
    // If not browser or supported, set background texture
    if (!(isBrowser && browserOnly.mapboxgl.supported())) {
      if (this.mapElement && this.mapElement.style) {
        this.mapElement.style.background = `url("/img/map-not-supported@2x.png")`
        this.mapElement.style.backgroundSize = `256px`
      }
      return
    }
    this.updateSize()

    // Add map
    this.map = new browserOnly.mapboxgl.Map({
      container: "map",
      style:
        "mapbox://styles/gemvintage/ck7at1wfs043y1inza4sbmwoy?optimize=true",
      bounds: this.expandBoundsForInitialZoom(defaultBounds),
      fitBoundsOptions: this.fitBoundsOptions(),
      scrollZoom: true,
      logoPosition: "bottom-right",
    })
    if (!isTouchDevice()) {
      this.map.addControl(new browserOnly.mapboxgl.NavigationControl())
    }
    this.map.addControl(
      new browserOnly.mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        fitBoundsOptions: this.fitBoundsOptions(),
      })
    )
    this.map.on("load", async () => {
      await this.initMapIcons()
      this.initSourcesAndLayers()
      this.addWheelBehavior()
      this.addResizeBehavior()
      this.addPanZoomBehavior()
      this.updateHoverMarker(this.props)
      this.updateMarkers(this.props)
      this.updateActiveMarker(this.props)
      this.calculateBounds(this.props)
      this.fitBounds()
      this.mapState.ready = true
    })
  }

  componentWillUnmount = () => {
    if (isBrowser && browserOnly.mapboxgl.supported()) {
      this.removeWheelBehavior()
      this.removeResizeBehavior()
      this.removePanZoomBehavior()
      this.map.remove()
    }
  }

  componentWillReceiveProps = (nextProps: MapProps) => {
    // Update map height
    this.updateSize(nextProps)

    if (!this.mapState.ready) {
      return
    }
    // Update map in specific situations

    // Always update Hovered
    this.updateHoverMarker(nextProps)

    // Update active only when changed
    if (nextProps.storeFocused !== this.props.storeFocused) {
      this.updateActiveMarker(nextProps)
      if (nextProps.storeFocused) {
        this.updateBounds()
        const activeCenter =
          nextProps.stores.byName[nextProps.storeFocused].center
        if (!this.insideBounds(activeCenter)) {
          // Active outside map bounds?
          this.calculateBounds(nextProps)
          this.fitBounds()
        } else if (this.map.getZoom() < 11.5) {
          // Distant zoom level?
          this.mapState.browsingAreaMode = true
          this.calculateBounds(nextProps)
          this.fitBounds()
        }
      }
    }

    // Changed category
    if (get(nextProps, "category.name") !== get(this.props, "category.name")) {
      this.mapState.browsingAreaMode = false
      this.updateMarkers(nextProps)
      if (get(nextProps, "category.name")) {
        this.calculateBounds(nextProps)
      } else if (!get(nextProps, "category.name") && !nextProps.storeFocused) {
        // Initial state
        this.mapState.bounds = defaultBounds
      }
      this.fitBounds()
      return
    }

    // Active => Inactive
    if (!nextProps.storeFocused && this.props.storeFocused) {
      this.mapState.browsingAreaMode = false
      this.calculateBounds(nextProps)
      this.fitBounds()
      return
    }
  }

  shouldComponentUpdate = () => {
    // DOM never changes
    return false
  }

  updateSize = (props = this.props) => {
    if (this.mapElement && this.mapElement.style) {
      if (this.mapState.wideView) {
        this.mapElement.style.height = `100%`
      } else {
        if (props.height) {
          this.mapElement.style.height = `${props.height}px`
        }
      }
      if (this.map && this.map.resize) {
        this.map.resize()
      }
    }
  }

  addWheelBehavior = () => {
    if (this.mapElement) {
      this.mapElement.addEventListener("wheel", this.handleWheelEvent)
    }
  }

  addResizeBehavior = () => {
    window.addEventListener("resize", this.handleResizeEvent)
  }

  removeResizeBehavior = () => {
    window.removeEventListener("resize", this.handleResizeEvent)
  }

  handleResizeEvent = (event: Object) => {
    if (this.mapState.resizeTimeout) {
      clearTimeout(this.mapState.resizeTimeout)
    }
    this.mapState.resizeTimeout = window.setTimeout(() => {
      this.mapState.wideView = window.innerWidth >= screenWidthBreakPoint
      this.updateSize()
      if (this.mapState.autoBounds) {
        this.calculateBounds(this.props)
        this.fitBounds()
      }
    }, 300)
  }

  removeWheelBehavior = () => {
    if (this.mapElement) {
      this.mapElement.removeEventListener("wheel", this.handleWheelEvent)
    }
  }

  handleWheelEvent = (event: any) => {
    if (this.mapState.wheelTimeout) {
      clearTimeout(this.mapState.wheelTimeout)
    }
    if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
      if (this.map.scrollZoom.isEnabled() === false) {
        this.map.scrollZoom.enable()
      }
    }
    this.mapState.wheelTimeout = window.setTimeout(() => {
      this.map.scrollZoom.disable()
    }, 300)
  }

  addPanZoomBehavior = () => {
    this.map.on("dragend", this.updateBounds)
    this.map.on("zoomend", this.updateBounds)
  }

  removePanZoomBehavior = () => {
    this.map.off("dragend", this.updateBounds)
    this.map.off("zoomend", this.updateBounds)
  }

  updateBounds = (event?: any) => {
    if (event && event.autoZoom) {
      return
    }
    let topLeft: PointLike = [0, 0]
    let bottomRight: PointLike = [window.innerWidth, window.innerHeight / 2]
    if (this.mapState.wideView) {
      topLeft = [350, 0]
      bottomRight = [window.innerWidth, window.innerHeight]
    }
    this.mapState.bounds = [
      this.map.unproject(topLeft),
      this.map.unproject(bottomRight),
    ]
    this.mapState.autoBounds = false
  }

  printBounds = () => {
    console.log(this.map.getBounds())
  }

  initMapIcons = (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      // Load and add pin images to map
      const highDensity = window.devicePixelRatio > 1.4
      const promises = assetHelper.assets.map((assetName) => {
        return new Promise((resolve, reject) => {
          const imageUrl = `/img/${assetName}${highDensity ? "@2x" : ""}.png`
          this.map.loadImage(imageUrl, (error, image) => {
            if (error) {
              console.log(error)
              return reject()
            }
            this.map.addImage(assetName, image, {
              pixelRatio: highDensity ? 2 : 1,
            })
            return resolve()
          })
        })
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        return reject()
      }
      return resolve()
    })
  }

  initSourcesAndLayers = () => {
    /* Sources and layers
      - markers
      - featuredMarkers
      - activeMarker
      - hoverMarker
      */

    const textStyles = {
      layout: {
        "text-field": "{name}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-size": 14,
        "text-offset": [0, -3.4],
        "text-anchor": "bottom" as const,
      },
      paint: {
        "text-halo-color": "rgba(255, 255, 255, 1)",
        "text-halo-width": 2,
      },
    }

    this.map.addSource("markers", {
      type: "geojson",
      data: null,
    })
    this.map.addLayer({
      id: "markers",
      source: "markers",
      type: "symbol",
      layout: {
        "icon-image": {
          type: "identity",
          property: "image",
        },
        "icon-anchor": "bottom",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 8, 0.3, 17, 0.6],
        "icon-allow-overlap": true,
      },
    })

    this.map.addSource("featuredMarkers", {
      type: "geojson",
      data: null,
    })
    this.map.addLayer({
      id: "featuredMarkers",
      source: "featuredMarkers",
      type: "symbol",
      layout: {
        "icon-image": {
          type: "identity",
          property: "image",
        },
        "icon-anchor": "bottom",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 8, 0.5, 17, 0.8],
        "icon-allow-overlap": true,
      },
    })
    const canHover = matchMedia("(hover: hover)").matches

    if (canHover) {
      ;["markers", "featuredMarkers"].forEach((layer) => {
        this.map.on("mousemove", layer, (event) => {
          this.map.getCanvas().style.cursor = "pointer"
          const storeName = get(event, "features[0].properties.name")
          if (this.props.storeFocused !== storeName) {
            this.props.setHovered(storeName)
          }
        })

        this.map.on("mouseleave", layer, (event) => {
          this.map.getCanvas().style.cursor = ""
          this.props.setHovered(null)
        })
      })
    }

    this.map.on("click", (event) => {
      const features = this.map.queryRenderedFeatures(event.point, {
        layers: ["markers", "featuredMarkers"],
      })
      // Ensure that the click is not on active pin
      let clickTarget = null
      features.some((feature) => {
        if (get(feature, "properties.name") !== this.props.storeFocused) {
          clickTarget = feature
          return true
        }
        return false
      })
      if (clickTarget == null) {
        return
      }
      const center = get(clickTarget, "geometry.coordinates")
      this.showTapWave(center)
      const storeName = get(clickTarget, "properties.name")
      let linkUrl
      if (
        this.props.category &&
        this.props.category.name &&
        this.props.category.stores.includes(storeName)
      ) {
        linkUrl = `${categoryPathForCategory(this.props.category)}/${slugify(
          storeName
        )}`
      } else {
        linkUrl = `/store/${slugify(storeName)}`
      }
      this.map.getCanvas().style.cursor = ""
      navigate(linkUrl)
      this.props.setHovered(null)
    })

    this.map.addSource("activeMarker", {
      type: "geojson",
      data: null,
    })
    this.map.addLayer({
      id: "activeMarker",
      source: "activeMarker",
      type: "symbol",
      layout: {
        "icon-image": {
          type: "identity",
          property: "image",
        },
        "icon-anchor": "bottom" as const,
        "icon-size": 1,
        "icon-ignore-placement": true,
        ...textStyles.layout,
        "text-offset": [0, -4.1],
      },
      paint: { ...textStyles.paint },
    })

    this.map.addSource("hoverMarker", {
      type: "geojson",
      data: null,
    })
    this.map.addLayer({
      id: "hoverMarker",
      source: "hoverMarker",
      type: "symbol",
      layout: {
        "icon-image": {
          type: "identity",
          property: "image",
        },
        "icon-anchor": "bottom",
        "icon-size": 0.8,
        "icon-ignore-placement": true,
        ...textStyles.layout,
      },
      paint: { ...textStyles.paint },
    })
  }

  updateMarkers = (props: MapProps) => {
    const markersGeoJson = {
      type: "FeatureCollection" as const,
      features: [],
    }
    const featuredMarkersGeoJson = {
      type: "FeatureCollection" as const,
      features: [],
    }
    if (!props.stores) {
      return
    }
    props.stores.names.forEach((name) => {
      const store = props.stores.byName[name]
      let assetName = assetHelper.pinAssetNameForTags(store.tags)
      const featured = props.category && props.category.stores.includes(name)
      if (!featured && props.category != null) {
        assetName = `${assetName}_gray`
      }
      const feature = {
        type: "Feature" as const,
        properties: {
          name: store.name,
          image: assetName,
        },
        geometry: {
          type: "Point" as const,
          coordinates: store.center,
        },
      }
      if (featured) {
        featuredMarkersGeoJson.features.push(feature)
      } else {
        markersGeoJson.features.push(feature)
      }
    })
    ;(this.map.getSource("markers") as GeoJSONSource).setData(markersGeoJson)
    ;(this.map.getSource("featuredMarkers") as GeoJSONSource).setData(
      featuredMarkersGeoJson
    )
  }

  updateHoverMarker = (props: MapProps) => {
    let features = []
    if (props.storeHovered) {
      const hoveredStore = props.stores.byName[props.storeHovered]
      let assetName = assetHelper.pinAssetNameForTags(hoveredStore.tags)
      const featured =
        !props.category || props.category.stores.includes(props.storeHovered)
      if (!featured) {
        assetName = `${assetName}_gray`
      }
      features.push({
        type: "Feature" as const,
        properties: {
          name: props.storeHovered,
          image: assetName,
        },
        geometry: {
          type: "Point" as const,
          coordinates: hoveredStore.center,
        },
      })
    }
    const hoverMarkerGeoJson = {
      type: "FeatureCollection" as const,
      features,
    }
    ;(this.map.getSource("hoverMarker") as GeoJSONSource).setData(
      hoverMarkerGeoJson
    )
  }

  updateActiveMarker = (props: MapProps) => {
    let features = []
    if (props.storeFocused) {
      const focusedStore = props.stores.byName[props.storeFocused]
      const assetName = assetHelper.pinAssetNameForTags(focusedStore.tags)
      features.push({
        type: "Feature" as const,
        properties: {
          name: props.storeFocused,
          image: assetName,
        },
        geometry: {
          type: "Point" as const,
          coordinates: focusedStore.center,
        },
      })
    }
    const activeMarkerGeoJson = {
      type: "FeatureCollection" as const,
      features,
    }
    ;(this.map.getSource("activeMarker") as GeoJSONSource).setData(
      activeMarkerGeoJson
    )
  }

  calculateBounds = (props: MapProps) => {
    if (!props.stores) {
      this.mapState.bounds = defaultBounds
      return
    }
    if (props.storeFocused) {
      const bounds = new browserOnly.LngLatBounds()
      const store = props.stores.byName[props.storeFocused]
      if (!store) {
        console.log("Store missing: " + props.storeFocused)
        return
      }
      bounds.extend(store.center)
      this.mapState.bounds = bounds
      return
    }
    if (get(props, "category.stores.length") > 0) {
      const bounds = new browserOnly.LngLatBounds()
      props.category.stores.forEach((storeName) => {
        const store = props.stores.byName[storeName]
        if (!store) {
          console.log("Store missing: " + storeName)
          return
        }
        bounds.extend(store.center)
      })
      this.mapState.bounds = bounds
      return
    }
    this.mapState.bounds = defaultBounds
  }

  fitBounds = () => {
    if (this.map) {
      this.map.fitBounds(this.mapState.bounds, this.fitBoundsOptions(), {
        autoZoom: true,
      })
    }
    this.mapState.autoBounds = true
  }

  insideBounds = (lngLat: [number, number]): boolean => {
    let mapBounds
    if (Array.isArray(this.mapState.bounds)) {
      mapBounds = this.mapState.bounds
    } else {
      // @ts-ignore
      mapBounds = this.mapState.bounds.toArray()
    }
    mapBounds = mapBounds.map((lngLat) => {
      if (Array.isArray(lngLat)) {
        return lngLat
      } else {
        return lngLat.toArray()
      }
    })
    if (
      lngLat[0] > mapBounds[0][0] &&
      lngLat[0] < mapBounds[1][0] &&
      lngLat[1] < mapBounds[0][1] &&
      lngLat[1] > mapBounds[1][1]
    ) {
      return true
    } else {
      return false
    }
  }

  showTapWave = (center: [number, number]) => {
    const tapWaveMarker = new browserOnly.mapboxgl.Marker(browserOnly.tw, {
      offset: [0, -10],
    })
      .setLngLat(center)
      .addTo(this.map)
    setTimeout(() => {
      tapWaveMarker.remove()
    }, 700)
  }

  fitBoundsOptions = () => {
    return {
      padding: this.mapState.wideView
        ? { left: 430, top: 50, right: 55, bottom: 90 }
        : { left: 35, top: 30, right: 35, bottom: 80 },
      offset: [0, 20] as [number, number],
      maxZoom: this.mapState.browsingAreaMode ? 13 : 16,
    }
  }

  expandBoundsForInitialZoom = (bounds: LngLatBoundsLike) => {
    const offset = 0.05
    return [
      [bounds[0][0] - offset, bounds[0][1] - offset],
      [bounds[1][0] + offset, bounds[1][1] + offset],
    ]
  }

  render = () => {
    return <MapDiv id="map" />
  }
}

export default Map

const initialState = {
  height: null,
}

export default (state: Object = initialState, action: any = {}) => {
  switch (action.type) {
    case "UPDATE_HEIGHT":
      return {
        ...state,
        height: Math.round(action.height / 2),
      }
    default:
      return state
  }
}

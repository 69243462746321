import * as React from "react"
import MapContainer from "./views/map/MapContainer"
import GlobalStyles from "./styles/GlobalStyles"
import Tracker from "./Tracker"
import Resizer from "./Resizer"
import "intersection-observer"
import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

import GlobalStylesForApp from "./styles/GlobalStylesForApp"
import ModalPortal from "./components/ModalPortal"
import "src/crashReporter/crashReporter"

interface PageWrapperBrowserProps {
  element: JSX.Element
  props: any
}

const PageWrapperBrowser: React.FC<PageWrapperBrowserProps> = ({
  element,
  props,
}) => {
  const pathname = props.location?.pathname
  if (pathname && pathname.startsWith("/app/")) {
    return (
      <>
        <GlobalStyles />
        <GlobalStylesForApp />
        {element}
        <Tracker location={props.location} />
      </>
    )
  }

  return (
    <ModalPortal>
      <GlobalStyles />
      <MapContainer {...props.pageContext} />
      {element}
      <Resizer />
      <Tracker location={props.location} />
    </ModalPortal>
  )
}

export default PageWrapperBrowser

import { css } from "styled-components"

export const primaryColor = "#FF3C5C"
export const listLink = "black"
export const contentPadding = 20
export const hairLine = "1px solid rgba(0, 0, 0, 0.1)"
export const hoverGray = "#f8f8f8"
export const overlayShadow = "0 2px 23px rgba(0, 0, 0, 0.14)"
export const bodyTextScreenSize = "14px"
export const bodyTextPrintSize = "9pt"
export const actionButtonHeight = "27px"
export const screenWidthBreakPoint = 640
export const computerContentWidth = "375px"
export const backgroundStyle = `
  background: rgba(255, 255, 255, 0.96);

  @supports (backdrop-filter: blur(4px)) {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
  }
`

export const contentMarginsCss = css`
  padding: 0 ${contentPadding}px;
  padding: 0 min(${contentPadding}px, 6vw);
`

export const cssMobileOnly = `@media screen and (max-width: ${
  screenWidthBreakPoint - 1
}px)`
export const cssComputerOnly = `@media screen and (min-width: ${screenWidthBreakPoint}px)`

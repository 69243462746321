import React from "react"
import { connect } from "react-redux"

import { updateHeight } from "./actions/screenActions"

const mapDispatchToProps = (dispatch) => {
  return {
    updateHeight: (height: number) => {
      dispatch(updateHeight(height))
    },
  }
}

type Props = {
  updateHeight: Function
}

class Resizer extends React.Component<Props> {
  prevWidth = 0
  prevHeight = 0

  componentWillMount = () => {
    if (window) {
      this.updateSize()
    }
  }

  componentDidMount = () => {
    if (window) {
      window.addEventListener("resize", this.resizeHandler)
    }
  }

  componentWillUnmount = () => {
    if (window) {
      window.removeEventListener("resize", this.resizeHandler)
    }
  }

  resizeHandler = () => {
    // Only resize if height changes more than 120
    // To address mobile status bar height changes
    const widthChange = Math.abs(this.prevWidth - window.innerWidth)
    const heightChange = Math.abs(this.prevHeight - window.innerHeight)
    if (widthChange === 0 && heightChange < 120) {
      return
    }
    this.updateSize()
  }

  updateSize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    this.prevWidth = width
    this.prevHeight = height
    this.props.updateHeight(height)
  }

  render = () => {
    return null
  }
}

export default connect(null, mapDispatchToProps)(Resizer)
